import $ from "jquery"

$(".js-back-to-top-middle").on('click', function() {
    $("html, body").animate({ scrollTop: 0 });
    return false;
});

if($('.archive.category .content-page').length){
    $('.archive.category .content-page .prefooter').prependTo('.archive.category .et-footers-wrapper');
}

if($('.archive.tag .content-page').length){
    $('.archive.tag .content-page .prefooter').prependTo('.archive.tag .et-footers-wrapper');
}